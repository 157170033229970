@dropdownBorderColor: #7d7d7d;

.ribbon-bar {
	position:absolute;
	right:0px;
	left:0px;
	top:0px;
	font-size: 14px;
	width: 100%;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: default;
	color: #333333;
	line-height: normal; //Bootstrap Compatibility
	*, :before, :after { //Bootstrap Compatibility
		box-sizing: content-box;
	}
	ul {
		list-style: none;
		margin: 0 0;
		padding: 0 0;
	}
	.command {
		overflow: hidden;
		vertical-align: top;

		&.disabled {
			opacity: 0.2;
		}

		&:not(.disabled) {
			cursor: pointer;
		}

		input[type=file] {
			display:none;
		}
		&:hover:not(.disabled), &.hover:not(.disabled), &.active, .running {
            background-color: @primayHighlightColor;
		}
		&.toggled {
			background-color: @toggledHighlightColor;
		}
        .caret-down {
            margin-left: 1px;
        }
	}

	// Header styling
	&> .header {
		padding-left:10px;
		height:40px;
		overflow: hidden;
		color: @primayColor;
		background-color: @primayBackgroundColor;
		&>.brand {
			padding-top: 10px;
			display: inline-block;
			font-size: 20px;
			line-height:20px;
			vertical-align:top;
			margin-right:5px;
		}
		&>.divider {
			display: inline-block;
			vertical-align:top;
			width:1px;
			height:30px;
			margin:5px 5px;
			background-image: url("../images/divider30.png");
		}
		&>.solution-dropdown {
			display: inline-block;
			&.hidden {
				display: none !important;
			}
			vertical-align:top;
			height: 20px;
			width: 20px;
			margin-top:5px;
			padding: 5px 5px;
			margin-left: -5px;
			margin-right: -5px;
			background-origin: content-box;
			background-repeat: no-repeat;
			background-image: url("../images/solutions.png");
		}
		&>.quick-save {
			display: inline-block;
			&.hidden {
				display: none !important;
			}
			vertical-align:top;
			height: 16px;
			width: 16px;
			padding:5px;
			margin-top:7px;
			background-origin: content-box;
			background-image: url("../images/save.png");
			background-repeat: no-repeat;
		}
		&>.current-file {
			display: inline-block;
			vertical-align:top;
			margin-top:10px;
            margin-bottom:12px;
			.path-segment {
				display: inline-block;
				vertical-align:top;
			}
			.path-separator {
				display: inline-block;
				vertical-align:top;
				width:16px;
				height:16px;
				margin-top:2px;
				background-image: url("../images/path-separator.png");
			}
		}
		&>.notification-dropdown {
			vertical-align: top;
			float: right;
			margin-top: 4px;
			padding: 3px;
			margin-right: 15%;
			text-align: center;
			font-size: 16px;
			width: 35px;
			.notification-button {
				position: relative;
				i {
					padding: 5px;
				}
				.badge {
					position: absolute;
					top: 0px;
					right: 1px;
					padding: 1px 2px;
					border-radius: 50%;
					background: red;
					color: white;
					font-size: 11px;
				}
                &.empty {
                    opacity: 0.5;
                }
			}
			.dropdown-content {
				display: block;
				position: absolute;
				width: 300px;
				max-height: 526px;
				top: 38px;
				overflow-y: auto;
				color: black;

				border: @dropdownBorderColor solid 1px;
				background-color: white;
				z-index: 100000000;
				&.hidden {
					display: none !important;
				}
				.notification {
					font-size: 13px;
					font-family: 'Open Sans', Arial, sans-serif;;
					padding: 10px 20px;
					text-align: initial;
					&.viewed {
						color: gray;
						background-color: #efefef;
					}
					.title {
						font-weight: bold;
						margin-bottom: 5px;
                        margin-right: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
					}
					.date {
						margin-top: 10px;
						font-size: 11px;
						color: gray;
						width: 50%;
						text-align: end;
						width: 100%;
					}
                    .close {
                        color: black;
                        opacity: 0.5;
                        float: right;
                        font-size: 16px;
                        font-weight: 700;
                        text-shadow: 0 1px 0 #fff;
                        margin-top: -2px;
                        &:hover {
                            opacity: 1;
                        }
                    }
					&:hover {
						background-color: @toggledHighlightColor;
					}
				}
				hr {
					margin: 0;
					border-color: darkgray;
				}
				p {
					margin-top: 10px;
					font-size: 13px;
					color: gray;
					&:hover {
						background-color: white;
						cursor: default;
					}
				}
			}
		}
		&>.look {
			display: inline-block;
			height: 40px;
			overflow: hidden;
			float: right;
		}
	}

	// Tabs styling
	&>.tabs {
		height: 30px;
		background-color: @borderColor;
		padding-left: 10px;
		overflow: hidden;
		&.hidden {
			display: none !important;
		}
		ul {
			display: inline-block;
			li {
				height: 30px;
				display: inline-block;
				&.hidden {
					display: none !important;
				}
				margin-right: 10px;
				margin-right: 10px;
				color: #000000;
				padding: 5px 5px;
				text-transform: uppercase;
				&.active {
					color: @primayBackgroundColor;
					background-color: #ffffff;
				}
				&:hover {
					color: @primayBackgroundColor;
				}
				&:not(.disabled) {
					cursor: pointer;
				}
			}

		}
		.tab-commands {
			float:right;
			margin-right:10px;
			height: 30px;
			.command {
				display:inline-block;
				&.hidden {
					display: none !important;
				}
				height: 24px;
				width: 24px;
				padding: 2px;
				margin: 2px;
				.image {
					height:16px;
					width:16px;
					margin-top:4px;
					margin-left:4px;
				}
				.label-container {
					display:none;
				}
			}

		}

	}

	// Ribbon styling
	&>.ribbon {
		background-color: #ffffff;
		border-bottom: 1px solid @borderColor;
		height:79px;
		padding-top:5px;
		padding-bottom:5px;
		font-size:11px;
		overflow: hidden;
		&.active {
			display: block;
		}
		&.hidden {
			display: none !important;
		}

		.caret-down {
			background-image: url('../images/down.png');
		}

		.group {
			position: relative;
			display: inline-block;
			&.hidden {
				display: none !important;
			}
			border-right: 1px solid @borderColor;
			padding-right: 5px;
			padding-left: 5px;
			height:79px;
			min-width:60px;
			float:left;
			margin-bottom:10px;

			.group-label {
				position: absolute;
				bottom: -5px;
				left: 0px;
				right: 0px;
				text-align: center;
				color: #666666;
				display: block;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				background-color: #ffffff;
			}

			.layout {
				display:inline-block;
				&.hidden {
					display: none !important;
				}
				&.large {
					height:69px;
					vertical-align: top;

					.command {
						position: relative;
						min-width: 50px;
						height: 69px;
						margin-right:2px;
						margin-left:2px;
						padding-left:2px;
						padding-right:2px;

						.image {
							text-align: center;
							width:32px;
							height:32px;
							margin-left: auto;
							margin-right: auto;
							margin-top: 3px;
							margin-bottom: 3px;
						}

						.label-container {
							text-align: center;
							font-size:12px;
							line-height: 15px;
							color: #000000;
							display: block;
							height: 30px;
							overflow:hidden;

							.caret-down {
								display: inline-block;
								height:12px;
								width:5px;
							}
						}

						.command-color {
							height: 3px;
							width: 32px;
							position: absolute;
							top: 36px;
							left: 9px;
						}
					}
				}

				&.small-container {
					height: 69px;
					vertical-align: top;
				}

				&.small-row-container {
					height: 24px;
					margin-bottom: 3px;
					vertical-align: top;
				}

				&.small {
					height:24px;
					margin-top:5px;

					.command {
						position:relative;
						display:block;
						height: 24px;
						margin-left: 2px;
						margin-right: 2px;
						padding-left: 5px;
						padding-right: 5px;
						padding-top: 3px;

						.image {
							float:left;
							width: 16px;
							height: 16px;
							margin-top: 3px;
							margin-bottom: 3px;
							display: inline-block;
						}

						.label-container {
							float:left;
							display: inline-block;
							padding-top:2px;
							font-size:12px;
							padding-left:6px;
							font-size:12px;

							.caret-down {
								display: inline-block;
								height:12px;
								width:5px;
							}
						}

						.command-color {
							height: 2px;
							width: 16px;
							position: absolute;
							top: 23px;
							left: 3px;
						}

					}
				}

				.command {
					display: inline-block;
					&.hidden {
						display: none !important;
					}

				}
			}
		}
	}
	#file-pane {
		z-index: 5000;
		position: fixed;
		left: -100000px;
		top: 0px;
		bottom: 0px;
		width: 100%;

		#file-pane-left {
			color: #ffffff;
			position: absolute;
			display: block;
			left: 0px;
			bottom: 0px;
			top: 0px;
			width: 140px;
			background-color: @primayBackgroundColor;

			.back {
				background-image: url('../images/back.png');
				width: 32px;
				height: 32px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 20px;
				margin-bottom: 20px;
				cursor: pointer;
			}

			#file-pane-identity {
				text-align: center;
				margin-bottom:10px;
				img#file-pane-identity-image {
					max-width: 48px;
					max-height: 48px;
				}
				#file-pane-identity-name {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					padding:5px;
				}

			}
			#file-commands {
				.command {
					margin-top: 2px;
					margin-bottom: 2px;
					padding: 5px 5px;
					&.hidden {
						display: none !important;
					}
					.image {
						vertical-align:top;
						display:inline-block;
						width: 16px;
						height: 16px;
						margin-right: 5px;
					}

					.label-container {
						font-size:12px;
						display:inline-block;
						vertical-align:top;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						max-width:108px;
					}
				}
			}
		}

		#file-pane-right {
			position: absolute;
			left: 140px;
			bottom: 0px;
			top: 0px;
			right: 0px;
			background-color: #ffffff;

			.file-pane-right-dropdown {
				display: none;
				width: 100%;
				height: 100%;
			}
		}
	}
	&>.dropdown-container {
		display: none;
		background-color: #ffffff;
		position: absolute;
		border: @dropdownBorderColor solid 1px;
		padding: 5px 0px;
		max-width: 50%;
		max-height: 500px;
		overflow-x: hidden;
		overflow-y: auto;

		.command {
			display:block;
			&.hidden {
				display: none !important;
			}
			padding: 7px 10px;
			.image {
				display:inline-block;
				width: 16px;
				height: 16px;
				margin-right: 5px;

			}

			.label-container {
				font-size:12px;
				display:inline-block;
				vertical-align:top;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

	}

	.input-dropdown {
		&.command {
			cursor: default;

			&:hover {
				background-color: inherit;
			}
		}
	}

	.input-dropdown-group {
		height: 20px;

		display: block;
		position: relative;

		outline: none;
		border-style: solid;
		border-width: 1px;
		border-color: #cccccc;

		*:not(.caret-down) {
			margin: 0px;
		}

		.caret-down {
			display: inline-block;
			height: 16px;
			width: 5px;
		}

		textarea:focus, input:focus {
			outline: none;
		}

		input {
			width: 75%;
			border: none;
			font-size: 12px;
			padding-left: 4px;
			background-color: transparent;
		}

		button {
			position: absolute;
			right: 0px;
			top: 0px;
			height: 100%;
			width: 20px;
			padding: 0px;
			border: none;
			background-color: #ffffff;

			&:hover:enabled, &:focus:enabled {
				outline: none;
				background-color: @toggledHighlightColor;
			}
		}

		&.active:not(.disabled), &:hover:not(.disabled) {
			border-color: @primayHighlightColor;
		}

		&.disabled {
			background-color: #ccc;
		}
	}

	& > .dynamic-dropdown-container {
		display: none;
		background-color: #ffffff;
		position: absolute;
		border: @dropdownBorderColor solid 1px;
		padding: 5px 0;
		max-width: 50%;
		max-height: 500px;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1;

		.command {
			display: block;
			padding: 7px 10px;

			&.hidden {
				display: none !important;
			}

			.image {
				display: inline-block;
				width: 16px;
				height: 16px;
				margin-right: 5px;
			}

			.label-container {
				font-size: 12px;
				display: inline-block;
				vertical-align: top;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}

	&>.dropdown-colorpicker {
		display:none;
		background-color: #ffffff;
		position: absolute;
		border: @dropdownBorderColor solid 1px;
		padding: 5px 5px;
		width: 136px;
		.color {
			display: inline-block;
			width: 11px;
			height: 11px;
			border: 1px solid @borderColor;
			margin: 2px 2px;
			cursor: pointer;
		}
	}
	#solution-dropdown {
		display: none;
		position: fixed;
		left: 20px;
        width: 690px;
		top: 38px;
		min-height: 80px;
		max-height: 80%;
		overflow-y: auto;

		border: @dropdownBorderColor solid 1px;
		background-color: #ffffff;
		z-index: 4900;
		padding: 10px 10px;
		.solution {
			float: left;
			width: 150px;
			max-width: 95%;
			height: 150px;
			border-width: 3px;
			border-color: #ffffff;
			border-style: solid;
			margin: 5px 5px;
			&:hover {
				border-color: #aaaaaa;
			}
		}
		hr {
			clear: both;
			margin-bottom: 0px;
			border-color: darkgray;
		}
				
		@media screen and (min-width: 960px) {
			width: 850px;
		}

		@media screen and (min-width: 1200px) {
			width: 1020px;
		}
	}

    .text-input {
        width: 250px;

        input {
            border: 1px solid @borderColor;
            font-size: 12px;
            width: 210px;
            float: left;
            height: 18px;
            margin-right: 5px;
            padding-left: 4px;
            &:focus {
                outline: none;
                border-color: @primayBackgroundColor;
            }
        }
        .image {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            padding: 3px;
            padding-left: 5px;
            &:hover {
                background-color: @primayHighlightColor;
            }
        }
        &.command:hover {
            background-color: inherit;
        }

    }

}
