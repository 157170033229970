.ribbon-bar {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  font-size: 14px;
  width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: default;
  color: #333333;
  line-height: normal;
}
.ribbon-bar *,
.ribbon-bar :before,
.ribbon-bar :after {
  box-sizing: content-box;
}
.ribbon-bar ul {
  list-style: none;
  margin: 0 0;
  padding: 0 0;
}
.ribbon-bar .command {
  overflow: hidden;
  vertical-align: top;
}
.ribbon-bar .command.disabled {
  opacity: 0.2;
}
.ribbon-bar .command:not(.disabled) {
  cursor: pointer;
}
.ribbon-bar .command input[type=file] {
  display: none;
}
.ribbon-bar .command:hover:not(.disabled),
.ribbon-bar .command.hover:not(.disabled),
.ribbon-bar .command.active,
.ribbon-bar .command .running {
  background-color: #80cfdb;
}
.ribbon-bar .command.toggled {
  background-color: #b3e2e9;
}
.ribbon-bar .command .caret-down {
  margin-left: 1px;
}
.ribbon-bar > .header {
  padding-left: 10px;
  height: 40px;
  overflow: hidden;
  color: #fafafa;
  background-color: #009eb6;
}
.ribbon-bar > .header > .brand {
  padding-top: 10px;
  display: inline-block;
  font-size: 20px;
  line-height: 20px;
  vertical-align: top;
  margin-right: 5px;
}
.ribbon-bar > .header > .divider {
  display: inline-block;
  vertical-align: top;
  width: 1px;
  height: 30px;
  margin: 5px 5px;
  background-image: url("../images/divider30.png");
}
.ribbon-bar > .header > .solution-dropdown {
  display: inline-block;
  vertical-align: top;
  height: 20px;
  width: 20px;
  margin-top: 5px;
  padding: 5px 5px;
  margin-left: -5px;
  margin-right: -5px;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-image: url("../images/solutions.png");
}
.ribbon-bar > .header > .solution-dropdown.hidden {
  display: none !important;
}
.ribbon-bar > .header > .quick-save {
  display: inline-block;
  vertical-align: top;
  height: 16px;
  width: 16px;
  padding: 5px;
  margin-top: 7px;
  background-origin: content-box;
  background-image: url("../images/save.png");
  background-repeat: no-repeat;
}
.ribbon-bar > .header > .quick-save.hidden {
  display: none !important;
}
.ribbon-bar > .header > .current-file {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 12px;
}
.ribbon-bar > .header > .current-file .path-segment {
  display: inline-block;
  vertical-align: top;
}
.ribbon-bar > .header > .current-file .path-separator {
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  background-image: url("../images/path-separator.png");
}
.ribbon-bar > .header > .notification-dropdown {
  vertical-align: top;
  float: right;
  margin-top: 4px;
  padding: 3px;
  margin-right: 15%;
  text-align: center;
  font-size: 16px;
  width: 35px;
}
.ribbon-bar > .header > .notification-dropdown .notification-button {
  position: relative;
}
.ribbon-bar > .header > .notification-dropdown .notification-button i {
  padding: 5px;
}
.ribbon-bar > .header > .notification-dropdown .notification-button .badge {
  position: absolute;
  top: 0px;
  right: 1px;
  padding: 1px 2px;
  border-radius: 50%;
  background: red;
  color: white;
  font-size: 11px;
}
.ribbon-bar > .header > .notification-dropdown .notification-button.empty {
  opacity: 0.5;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content {
  display: block;
  position: absolute;
  width: 300px;
  max-height: 526px;
  top: 38px;
  overflow-y: auto;
  color: black;
  border: #7d7d7d solid 1px;
  background-color: white;
  z-index: 100000000;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content.hidden {
  display: none !important;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content .notification {
  font-size: 13px;
  font-family: 'Open Sans', Arial, sans-serif;
  padding: 10px 20px;
  text-align: initial;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content .notification.viewed {
  color: gray;
  background-color: #efefef;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content .notification .title {
  font-weight: bold;
  margin-bottom: 5px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content .notification .date {
  margin-top: 10px;
  font-size: 11px;
  color: gray;
  width: 50%;
  text-align: end;
  width: 100%;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content .notification .close {
  color: black;
  opacity: 0.5;
  float: right;
  font-size: 16px;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
  margin-top: -2px;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content .notification .close:hover {
  opacity: 1;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content .notification:hover {
  background-color: #b3e2e9;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content hr {
  margin: 0;
  border-color: darkgray;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content p {
  margin-top: 10px;
  font-size: 13px;
  color: gray;
}
.ribbon-bar > .header > .notification-dropdown .dropdown-content p:hover {
  background-color: white;
  cursor: default;
}
.ribbon-bar > .header > .look {
  display: inline-block;
  height: 40px;
  overflow: hidden;
  float: right;
}
.ribbon-bar > .tabs {
  height: 30px;
  background-color: #e1e1e1;
  padding-left: 10px;
  overflow: hidden;
}
.ribbon-bar > .tabs.hidden {
  display: none !important;
}
.ribbon-bar > .tabs ul {
  display: inline-block;
}
.ribbon-bar > .tabs ul li {
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  color: #000000;
  padding: 5px 5px;
  text-transform: uppercase;
}
.ribbon-bar > .tabs ul li.hidden {
  display: none !important;
}
.ribbon-bar > .tabs ul li.active {
  color: #009eb6;
  background-color: #ffffff;
}
.ribbon-bar > .tabs ul li:hover {
  color: #009eb6;
}
.ribbon-bar > .tabs ul li:not(.disabled) {
  cursor: pointer;
}
.ribbon-bar > .tabs .tab-commands {
  float: right;
  margin-right: 10px;
  height: 30px;
}
.ribbon-bar > .tabs .tab-commands .command {
  display: inline-block;
  height: 24px;
  width: 24px;
  padding: 2px;
  margin: 2px;
}
.ribbon-bar > .tabs .tab-commands .command.hidden {
  display: none !important;
}
.ribbon-bar > .tabs .tab-commands .command .image {
  height: 16px;
  width: 16px;
  margin-top: 4px;
  margin-left: 4px;
}
.ribbon-bar > .tabs .tab-commands .command .label-container {
  display: none;
}
.ribbon-bar > .ribbon {
  background-color: #ffffff;
  border-bottom: 1px solid #e1e1e1;
  height: 79px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 11px;
  overflow: hidden;
}
.ribbon-bar > .ribbon.active {
  display: block;
}
.ribbon-bar > .ribbon.hidden {
  display: none !important;
}
.ribbon-bar > .ribbon .caret-down {
  background-image: url('../images/down.png');
}
.ribbon-bar > .ribbon .group {
  position: relative;
  display: inline-block;
  border-right: 1px solid #e1e1e1;
  padding-right: 5px;
  padding-left: 5px;
  height: 79px;
  min-width: 60px;
  float: left;
  margin-bottom: 10px;
}
.ribbon-bar > .ribbon .group.hidden {
  display: none !important;
}
.ribbon-bar > .ribbon .group .group-label {
  position: absolute;
  bottom: -5px;
  left: 0px;
  right: 0px;
  text-align: center;
  color: #666666;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #ffffff;
}
.ribbon-bar > .ribbon .group .layout {
  display: inline-block;
}
.ribbon-bar > .ribbon .group .layout.hidden {
  display: none !important;
}
.ribbon-bar > .ribbon .group .layout.large {
  height: 69px;
  vertical-align: top;
}
.ribbon-bar > .ribbon .group .layout.large .command {
  position: relative;
  min-width: 50px;
  height: 69px;
  margin-right: 2px;
  margin-left: 2px;
  padding-left: 2px;
  padding-right: 2px;
}
.ribbon-bar > .ribbon .group .layout.large .command .image {
  text-align: center;
  width: 32px;
  height: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  margin-bottom: 3px;
}
.ribbon-bar > .ribbon .group .layout.large .command .label-container {
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  display: block;
  height: 30px;
  overflow: hidden;
}
.ribbon-bar > .ribbon .group .layout.large .command .label-container .caret-down {
  display: inline-block;
  height: 12px;
  width: 5px;
}
.ribbon-bar > .ribbon .group .layout.large .command .command-color {
  height: 3px;
  width: 32px;
  position: absolute;
  top: 36px;
  left: 9px;
}
.ribbon-bar > .ribbon .group .layout.small-container {
  height: 69px;
  vertical-align: top;
}
.ribbon-bar > .ribbon .group .layout.small-row-container {
  height: 24px;
  margin-bottom: 3px;
  vertical-align: top;
}
.ribbon-bar > .ribbon .group .layout.small {
  height: 24px;
  margin-top: 5px;
}
.ribbon-bar > .ribbon .group .layout.small .command {
  position: relative;
  display: block;
  height: 24px;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
}
.ribbon-bar > .ribbon .group .layout.small .command .image {
  float: left;
  width: 16px;
  height: 16px;
  margin-top: 3px;
  margin-bottom: 3px;
  display: inline-block;
}
.ribbon-bar > .ribbon .group .layout.small .command .label-container {
  float: left;
  display: inline-block;
  padding-top: 2px;
  padding-left: 6px;
  font-size: 12px;
}
.ribbon-bar > .ribbon .group .layout.small .command .label-container .caret-down {
  display: inline-block;
  height: 12px;
  width: 5px;
}
.ribbon-bar > .ribbon .group .layout.small .command .command-color {
  height: 2px;
  width: 16px;
  position: absolute;
  top: 23px;
  left: 3px;
}
.ribbon-bar > .ribbon .group .layout .command {
  display: inline-block;
}
.ribbon-bar > .ribbon .group .layout .command.hidden {
  display: none !important;
}
.ribbon-bar #file-pane {
  z-index: 5000;
  position: fixed;
  left: -100000px;
  top: 0px;
  bottom: 0px;
  width: 100%;
}
.ribbon-bar #file-pane #file-pane-left {
  color: #ffffff;
  position: absolute;
  display: block;
  left: 0px;
  bottom: 0px;
  top: 0px;
  width: 140px;
  background-color: #009eb6;
}
.ribbon-bar #file-pane #file-pane-left .back {
  background-image: url('../images/back.png');
  width: 32px;
  height: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.ribbon-bar #file-pane #file-pane-left #file-pane-identity {
  text-align: center;
  margin-bottom: 10px;
}
.ribbon-bar #file-pane #file-pane-left #file-pane-identity img#file-pane-identity-image {
  max-width: 48px;
  max-height: 48px;
}
.ribbon-bar #file-pane #file-pane-left #file-pane-identity #file-pane-identity-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
}
.ribbon-bar #file-pane #file-pane-left #file-commands .command {
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 5px 5px;
}
.ribbon-bar #file-pane #file-pane-left #file-commands .command.hidden {
  display: none !important;
}
.ribbon-bar #file-pane #file-pane-left #file-commands .command .image {
  vertical-align: top;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.ribbon-bar #file-pane #file-pane-left #file-commands .command .label-container {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 108px;
}
.ribbon-bar #file-pane #file-pane-right {
  position: absolute;
  left: 140px;
  bottom: 0px;
  top: 0px;
  right: 0px;
  background-color: #ffffff;
}
.ribbon-bar #file-pane #file-pane-right .file-pane-right-dropdown {
  display: none;
  width: 100%;
  height: 100%;
}
.ribbon-bar > .dropdown-container {
  display: none;
  background-color: #ffffff;
  position: absolute;
  border: #7d7d7d solid 1px;
  padding: 5px 0px;
  max-width: 50%;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.ribbon-bar > .dropdown-container .command {
  display: block;
  padding: 7px 10px;
}
.ribbon-bar > .dropdown-container .command.hidden {
  display: none !important;
}
.ribbon-bar > .dropdown-container .command .image {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.ribbon-bar > .dropdown-container .command .label-container {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ribbon-bar .input-dropdown.command {
  cursor: default;
}
.ribbon-bar .input-dropdown.command:hover {
  background-color: inherit;
}
.ribbon-bar .input-dropdown-group {
  height: 20px;
  display: block;
  position: relative;
  outline: none;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
}
.ribbon-bar .input-dropdown-group *:not(.caret-down) {
  margin: 0px;
}
.ribbon-bar .input-dropdown-group .caret-down {
  display: inline-block;
  height: 16px;
  width: 5px;
}
.ribbon-bar .input-dropdown-group textarea:focus,
.ribbon-bar .input-dropdown-group input:focus {
  outline: none;
}
.ribbon-bar .input-dropdown-group input {
  width: 75%;
  border: none;
  font-size: 12px;
  padding-left: 4px;
  background-color: transparent;
}
.ribbon-bar .input-dropdown-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 20px;
  padding: 0px;
  border: none;
  background-color: #ffffff;
}
.ribbon-bar .input-dropdown-group button:hover:enabled,
.ribbon-bar .input-dropdown-group button:focus:enabled {
  outline: none;
  background-color: #b3e2e9;
}
.ribbon-bar .input-dropdown-group.active:not(.disabled),
.ribbon-bar .input-dropdown-group:hover:not(.disabled) {
  border-color: #80cfdb;
}
.ribbon-bar .input-dropdown-group.disabled {
  background-color: #ccc;
}
.ribbon-bar > .dynamic-dropdown-container {
  display: none;
  background-color: #ffffff;
  position: absolute;
  border: #7d7d7d solid 1px;
  padding: 5px 0;
  max-width: 50%;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
}
.ribbon-bar > .dynamic-dropdown-container .command {
  display: block;
  padding: 7px 10px;
}
.ribbon-bar > .dynamic-dropdown-container .command.hidden {
  display: none !important;
}
.ribbon-bar > .dynamic-dropdown-container .command .image {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.ribbon-bar > .dynamic-dropdown-container .command .label-container {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ribbon-bar > .dropdown-colorpicker {
  display: none;
  background-color: #ffffff;
  position: absolute;
  border: #7d7d7d solid 1px;
  padding: 5px 5px;
  width: 136px;
}
.ribbon-bar > .dropdown-colorpicker .color {
  display: inline-block;
  width: 11px;
  height: 11px;
  border: 1px solid #e1e1e1;
  margin: 2px 2px;
  cursor: pointer;
}
.ribbon-bar #solution-dropdown {
  display: none;
  position: fixed;
  left: 20px;
  width: 690px;
  top: 38px;
  min-height: 80px;
  max-height: 80%;
  overflow-y: auto;
  border: #7d7d7d solid 1px;
  background-color: #ffffff;
  z-index: 4900;
  padding: 10px 10px;
}
.ribbon-bar #solution-dropdown .solution {
  float: left;
  width: 150px;
  max-width: 95%;
  height: 150px;
  border-width: 3px;
  border-color: #ffffff;
  border-style: solid;
  margin: 5px 5px;
}
.ribbon-bar #solution-dropdown .solution:hover {
  border-color: #aaaaaa;
}
.ribbon-bar #solution-dropdown hr {
  clear: both;
  margin-bottom: 0px;
  border-color: darkgray;
}
@media screen and (min-width: 960px) {
  .ribbon-bar #solution-dropdown {
    width: 850px;
  }
}
@media screen and (min-width: 1200px) {
  .ribbon-bar #solution-dropdown {
    width: 1020px;
  }
}
.ribbon-bar .text-input {
  width: 250px;
}
.ribbon-bar .text-input input {
  border: 1px solid #e1e1e1;
  font-size: 12px;
  width: 210px;
  float: left;
  height: 18px;
  margin-right: 5px;
  padding-left: 4px;
}
.ribbon-bar .text-input input:focus {
  outline: none;
  border-color: #009eb6;
}
.ribbon-bar .text-input .image {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding: 3px;
  padding-left: 5px;
}
.ribbon-bar .text-input .image:hover {
  background-color: #80cfdb;
}
.ribbon-bar .text-input.command:hover {
  background-color: inherit;
}
